import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { Layout } from 'src/components/Layout';
import { SEO } from 'src/components/SEO';

const IndexPage = () => (
  <Layout
    className="landing-page"
    landingPage
    noFooter
  >
    <SEO title="Home" />
    <div className="landing-page__hero">
      <StaticImage
        className="landing-page__image"
        src="../assets/images/alviso-team.png"
        alt="Team"
        layout='fullWidth'
        objectPosition='bottom'
        quality={100}
      />
      <div className="landing-page__heading container">
        <h1>We are Alviso</h1>
        <p>
          IT focused recruitment consulting company.<br />
          We know who, when, why and for how much.
        </p>
      </div>
    </div>
    <div className="container landing-page__btns">
      <div className="row">
        <div className="col-12 col-lg-6 text-center">
          <Link
            className="landing-page__cta"
            to="/team"
          >
            <h1>Grow<br />your team!</h1>
          </Link>
        </div>
        <div className="col-12 col-lg-6 text-center">
          <Link
            className="landing-page__cta"
            to="/career"
          >
            <h1>Your<br />career</h1>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
